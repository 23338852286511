<template>
  <div class="aops-wish-list">
    <div>
      <el-table
        :data="listData"
        header-cell-class-name="table-head"
        cell-class-name="table-cell"
        v-loading="tableLoading"
        class="data-table aop-table"
        ref="promoTable">
        <el-table-column
            v-for="col in tableColumns"
            :key="col.label"
            :prop="col.prop"
            :label="col.label"
            :width="col.width"
            :align="col.align"
            :fixed="col.fixed">
            <template slot-scope="{row}">
                <div v-if="col.prop=== 'client'">
                  <span>{{row.client === 1 ? 'PBRMAX' : 'INCEPTION'}}</span>
                </div>
                <div v-else-if="col.prop === 'type'">
                   {{getType(row.type) || '--'}}
                </div>
                <div v-else-if="col.prop === 'status'">
                   <span>{{row.status === 1 ? '已处理' : '未处理'}}</span>
                </div>
                <div v-else-if="col.prop === 'operation'" class="icon-btn">
                     <div class="icon-btn">
                        <el-button size="mini" type="text" @click="handleOpen(row)" v-if="hasResource('collect_feedback_info')" >
                            <i class="iconfont">&#xe662;</i>查看
                        </el-button>
                        <el-button v-if="!row.status && hasResource('collect_feedback_change')" size="mini" type="text" @click="showDialog(row)">
                              <i class="iconfont">&#xe65c;</i>处理
                        </el-button>
                    </div>
                </div>
                <div v-else>{{row[col.prop] || '--'}}</div>
            </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="aop-pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!-- 处理弹窗 -->
    <div class="aops-handleProblem pop-dialog">
    <el-dialog
        top="12%"
        :visible.sync="dialogVisible"
        width="458px"
        class="pop-dialog"
        :modal-append-to-body="false"
      >
        <div class="aop-title">您已处理了该问题？</div>
        <span slot="footer" class="aop-footer dialog-footer">
          <el-button class="confirm-button" :loading="btnLoading" @click="confirm">确 认</el-button>
          <el-button class="cancel-button" @click="dialogVisible = false">取 消</el-button>
        </span>
      </el-dialog>
  </div>

  </div>
</template>

<script>
import bus from "./bus";
import api from "@/api/user";

export default {
    /**
    * 基础数据&定义数据
        @return{*}
    */
  data() {
    return {
     tableColumns:[
        { prop: "feedback_number", label: "许愿单号", width: 150, align: "left" },
        { prop: "created_at", label: "提交时间", width: 200, align: "left" },
        { prop: "client", label: "应用端", width: 150, align: "left" },
        { prop: "contact_name",  label: "用户名称", width: 150, align: "left" },
        { prop: "contact_email",  label: "用户邮箱", width: 250, align: "left" },
        { prop: "type", label: "类型", width: 150, align: "left" },
        { prop: "status", label: "状态", width: 150, align: "left" },
        { prop: "operation", label: "操作", width: 280, align: "left",fixed:"right"},
     ],
      listData: [],
      tableLoading: false,
      currentPage: 1,
      pageSize: 10,
      filter: {},
      total: 0,
      currentItem: null,
      dialogVisible: false,
      btnLoading: false
    };
  },
  /**
  * computed
   */
  computed: {
    queryInfo() {
      const query = {
        limit: this.pageSize,
        page: this.currentPage,
        filter: this.filter,
        sort: "desc",
        order_by: "id",
      };
      return query;
    },
  },
  methods: {
    /**
    * 初始化
    */
    init() {
      this.getCollection();
      this.btnLoading = false;
      this.currentItem = null;
      this.dialogVisible = false;
    },
     /**
    * 获取数据
    */
    getCollection() {
      this.tableLoading = true;
      api.collectionList(this.queryInfo).then((res) => {
        if (res.data.code === 10200) {
          this.listData = res.data.data.items;
          this.total = res.data.data.total;
        }
        this.tableLoading = false;
      });
    },
    /**
    * 处理Size
    */
    handleSizeChange(val) {
      this.pageSize = val;
      this.init();
    },
    /**
    * 处理currentPage
    */
    handleCurrentChange(val) {
      this.currentPage = val;
      this.init();
    },
    /**
    * 查看数据
    */
    handleOpen(row) {
      this.$emit("handleOpen", row);
    },
    /**
    * 获取类型
    * @param {*} type 值
    */
    getType(type) {
        switch (type) {
            case 1:
                return "3D Assets";
            case 2:
                return "Surface";
            case 3:
                return "Others";
        }
    },
    /**
    * 显示弹窗
    * @param {*} row 值
    */
    showDialog(row) {
      this.currentItem = row;
      this.dialogVisible = true;
      this.btnLoading = false;
    },
    /**
    * 问题处理
    */
    confirm() {
      this.btnLoading = true;
      const data = {
        id: (this.currentItem && this.currentItem.id) || '',
        status: 1
      };
      try {
        api.changeStatus(data).then((res) => {
            if (res.data.code === 10200) {
                this.dialogVisible = false;
                this.$$success('操作成功');
                this.init();
            }
        });
      } catch (e) {
        return e;
     }
    },
  },
  /**
  * mounted
  */
  mounted() {
    this.init();
  },
  /**
  * created
  */
  created() {
    bus.$on("makeFilter", (val) => {
      this.filter = val;
      this.currentPage = 1;
      this.init();
    });
  },
};
</script>
<style lang="less">
@import "./scss/list.less";
</style>
