<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      title="查看"
      :visible.sync="dialogVisible"
      width="600px"
      top="100px"
      :modal-append-to-body="false"
      class="pop-dialog promo-add-dialog collection-dialog"
    >
      <el-form
        ref="infoForm"
        :model="collectionInfo"
        label-width="80px"
        label-position="left"
      >
        <el-form-item label="用户名称">
          {{ collectionInfo.contact_name || "--" }}
        </el-form-item>
        <el-form-item label="用户邮箱">
          {{ collectionInfo.contact_email || "--" }}
        </el-form-item>
        <el-form-item label="类型">
          <span v-if="collectionInfo.type === 1">3D Assets</span>
          <span v-if="collectionInfo.type === 2">Surface</span>
          <span v-if="collectionInfo.type === 3">Others</span>
          <span v-if="collectionInfo.type === 3" class="mg-l-12">
            ({{ collectionInfo.type_name }})
          </span>
        </el-form-item>
        <el-form-item label="时间">
          {{ collectionInfo.created_at || "--" }}
        </el-form-item>
        <el-form-item label="内容">
          <div class="collection-content">
            <div>
              {{ collectionInfo.content ? collectionInfo.content : "" }}
            </div>
            <div class="collection-image mg-t-12">
              <div
                v-for="(item, index) in collectionInfo.images"
                :key="index"
                class="img-box"
              >
                <img :src="item" alt="" />
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/user";
export default {
  data() {
    return {
      dialogVisible: false,
      collectionInfo: {},
    };
  },
  methods: {
    getCollectionInfo(item) {
      api.collectionInfo(item.id).then((res) => {
        if (res.data.code === 10200) {
          this.collectionInfo = res.data.data;
        }
      });
    },
    show(item) {
      this.collectionInfo = {};
      this.dialogVisible = true;
      this.getCollectionInfo(item);
    },
  },
};
</script>
<style lang="less">
@import "../scss/info.less";
</style>
