export const type = [
  {
    value: "",
    label: "全部",
  },
  {
    value: "1",
    label: "3D Assets",
  },
  {
    value: "2",
    label: "Surface",
  },
  {
    value: "3",
    label: "Other",
  },
];

export const client = [
  {
    value: "",
    label: "全部",
  },
  {
    value: "1",
    label: "PBRMAX",
  },
  {
    value: "2",
    label: "INCEPTION",
  },
];

export const status = [
  {
    value: "",
    label: "全部",
  },
  {
    value: "1",
    label: "已处理",
  },
  {
    value: "0",
    label: "未处理",
  },
];
