<template>
  <div  v-if="hasResource('collect_feedback_management')">
    <div class="search-form">
      <wish-search></wish-search>
    </div>
    <wish-list @handleOpen="handleOpen"></wish-list>
    <wish-info ref="wishInfoActivation"></wish-info>
  </div>
</template>

<script>
import { wishSearch, wishList, wishInfo } from "./components/index";
export default {
  name: "customerWishLists",
  components: { wishSearch, wishList, wishInfo },
  methods: {
    handleOpen(val) {
      this.$nextTick(() => {
        this.$refs.wishInfoActivation.show(val);
      });
    },
  },
};
</script>
