<template>
  <div class="dis-fix">
    <el-form
      :inline="true"
      :model="queryForm"
      ref="collectionFilter"
      :rules="rules"
    >
      <el-form-item label="许愿单号" prop="feedback_number">
        <el-input
          placeholder="请输入许愿单号"
          v-model="queryForm.feedback_number"
        ></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select v-model="queryForm.type">
          <el-option
            v-for="item in type"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提交时间" prop="created_at">
        <el-date-picker
          :picker-options="datePick"
          v-model="queryForm.created_at"
          style="width: 168px"
          format="yyyy.MM.dd"
          type="daterange"
          range-separator="-"
          :maxTime="today"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="请选择"
          end-placeholder="请选择"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="应用端" prop="client">
        <el-select v-model="queryForm.client">
          <el-option
            v-for="item in client"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select v-model="queryForm.status">
          <el-option
            v-for="item in status"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="text-btn">
      <span class="serach-button" @click="send">查询</span>
      <span class="clear-button" @click="clearSearch">清空</span>
    </div>
  </div>
</template>

<script>
import { type, client, status } from "./var";
import bus from "./bus";
export default {
  data() {
    var disableEndDate = (time) => {
      let _now = Date.now();
      //大于当前的禁止
      return time.getTime() > _now;
    };
    return {
      queryForm: {
        feedback_number: "",
        status: "",
        type: "",
        created_at: [],
        client: "",
      },
      status: status,
      type: type,
      client: client,
      today: new Date(),
      datePick: {
        disabledDate(time) {
          return disableEndDate(time);
        },
      },
      rules: {
        feedback_number: [
          {
            min: 0,
            max: 10,
            message: "许愿单号长度不超过10位",
          },
        ],
      },
    };
  },
  methods: {
    send() {
      const form = {
        feedback_number: this.queryForm.feedback_number,
        status: this.queryForm.status,
        type: this.queryForm.type,
        client: this.queryForm.client,
        created_at: {
          from:
            this.queryForm.created_at && this.queryForm.created_at.length > 1
              ? this.queryForm.created_at[0]
              : "",
          to:
            this.queryForm.created_at && this.queryForm.created_at.length > 1
              ? this.queryForm.created_at[1].split(" ")[0] + " 23:59:59"
              : "",
        },
      };
      bus.$emit("makeFilter", form);
    },
    clearSearch() {
      this.clearForm("collectionFilter");
    },
  },
};
</script>
